<template>
  <div class="trx-body">
    <div class="header-title">
      <div class="col-9 d-flex align-items-center pl-0 flex-wrap">
        <b>{{ $t("Transaction") }}</b>
        <div class="trx-btn-container">
          <div class="trx-inner-container">
            <button
              :class="switch_tab == 1 ? 'trx-btn trx-btn_active' : 'trx-btn'"
              @click="switch_tab = 1"
            >
              <inline-svg
                :src="require('../assets/icon/new-icon/import.svg')"
                width="25"
                height="25"
                aria-label="copy"
              ></inline-svg>
              <div class="pl-2">{{ $t("Receive") }}</div>
            </button>

            <button
              :class="
                switch_tab == 2 ? 'trx-btn trx-btn_active ml-3' : 'trx-btn ml-3'
              "
              @click="switch_tab = 2"
            >
              <inline-svg
                :src="require('../assets/icon/new-icon/send.svg')"
                width="25"
                height="25"
                aria-label="copy"
              ></inline-svg>
              <div class="pl-2">{{ $t("Send") }}</div>
            </button>
          </div>
        </div>
      </div>
      <div class="col-3 text-right">
        <button
          class="btn p-0 text-danger"
          type="button"
          v-b-modal.rescan-modal
        >
          <b class="mr-1">{{ $t("Missing Transaction") }}</b>
          <b-icon-question-circle-fill
            variant="danger"
          ></b-icon-question-circle-fill>
        </button>
      </div>
    </div>

    <!-- Rescan Modal -->
    <b-modal
      id="rescan-modal"
      :title="$t('Rescan Missing Transaction')"
      hide-footer
    >
      <form @submit.prevent="rescan">
        <div class="form-group">
          <label for="feedback-user">{{ $t("Network") }}</label>
          <b-form-select
            class="text-left"
            v-model="selectedNetwork"
            :options="networks"
          ></b-form-select>
        </div>

        <div class="form-group">
          <label for="feedback-user">{{ $t("Block Number") }}</label>
          <b-input type="number" v-model="blockNumber"></b-input>
        </div>

        <div class="form-group w-50 mt-5 mx-auto text-center">
          <b-button type="submit" class="activate-btn" v-if="!isSubmitting">{{
            $t("Rescan")
          }}</b-button>
          <b-button type="button" class="activate-btn" v-else>
            <b-spinner small variant="success" label="Spinning"></b-spinner>
          </b-button>
        </div>
      </form>
    </b-modal>

    <div>
      <!-- Receive -->
      <div class="mt-2" v-if="switch_tab === 1">
        <div class="rescan-container p-2">
          <div class="text-center">
            <div class="total-readonly">{{ $t("TOTAL AMOUNT") }}</div>
            <span>{{
              formatNum(totalDepositAmount ?? 0, deposit_volume_decimal)
            }}</span>
          </div>

          <div class="text-center">
            <div class="total-readonly">{{ $t("TOTAL CHARGE FEE") }}</div>
            <span>{{
              formatNum(totalDepositChargeFee ?? 0, deposit_volume_decimal + 2)
            }}</span>
          </div>

          <div class="text-center">
            <div class="total-readonly">{{ $t("TOTAL GAS") }}</div>
            <span>{{
              formatNum(totalDepositGas ?? 0, deposit_volume_decimal + 2)
            }}</span>
          </div>
        </div>

        <!-- Filter Section -->
        <div class="d-flex flex-wrap mt-3">
          <div class="table-header col-3 px-0">
            <div class="table-header-text">{{ $t("TX/ID") }}</div>
            <div class="search-box">
              <input
                type="search"
                class="txID-input form-control"
                :placeholder="$t(`Enter TX/ID`)"
                v-model="depositHash"
              />
              <img
                src="../assets/images/search.png"
                @click="fetchDepositHistory"
              />
            </div>
          </div>

          <div class="table-header">
            <div class="table-header-text">{{ $t("coin") }}</div>
            <div>
              <!-- <select v-model="coinDepositSelected" @change="changeDepositSelectedSymbol" id="coinSortList"
                class="tx-dropdown">
                <option v-for="coinOption in coinOptions" :value="coinOption" :key="coinOption.id">
                  {{ coinOption.symbol }}
                </option>
              </select> -->
              <b-dropdown class="tx-filter tx-dropdown">
                <template #button-content>
                  <img
                    class="mr-2"
                    :src="coinDepositSelected?.image"
                    alt="coin-img-savvix"
                    width="25px"
                    height="25px"
                  />
                  <b class="mr-2"
                    >{{ coinDepositSelected?.symbol }}
                    <small>{{ coinDepositSelected?.network.symbol }}</small></b
                  >
                </template>
                <b-dropdown-item
                  v-for="coinOption in coinOptions"
                  :value="coinOption"
                  :key="coinOption.id"
                  @click="changeDepositSelectedSymbol(coinOption)"
                >
                  <img
                    class="mr-2"
                    :src="coinOption?.image"
                    alt="coin-savvix-img"
                    width="25px"
                    height="25px"
                  />
                  <b
                    >{{ coinOption?.symbol }}
                    <small>{{ coinOption?.network.symbol }}</small>
                  </b>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>

          <div class="table-header">
            <div class="table-header-text">{{ $t("status") }}</div>
            <!-- <div>
              <select v-model="selectedDepositStatus" id="coinList" class="tx-dropdown">
                <option v-for="status in depositStatus" :value="status.value" :key="`deposit${status.value}`">
                  {{ status.text }}
                </option>
              </select>
            </div> -->
            <b-dropdown class="tx-filter tx-dropdown">
              <template #button-content>
                <b class="mr-3">{{ selectedDepositStatus?.text }}</b>
              </template>
              <b-dropdown-item
                v-for="(statusObj, index) in depositStatus"
                :key="`deposit${statusObj.value}`"
                @click="changeDepositSelectedStatus(index)"
              >
                <b>{{ statusObj.text }}</b>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div class="table-header">
            <div class="table-header-text">{{ $t("Date/Time") }}</div>
            <div class="d-flex flex-column">
              <date-range-picker
                v-model="depositDateRange"
                @update="console.log(value)"
                :locale-data="locale"
              >
                <!--Optional scope for the input displaying the dates -->
                <template v-slot:input="picker">
                  {{ picker.startDate.toISOString().split("T")[0] }} -
                  {{ picker.endDate.toISOString().split("T")[0] }}
                </template>
              </date-range-picker>
              <!-- <input v-model="depositEndDate" :placeholder="$t(`End`)" class="txID-input" type="text"
                onfocus="(this.type='date')" onblur="(this.type='text')" :min="depositStartDate" /> -->
            </div>
          </div>

          <div class="table-header refresh-div">
            <b-button class="refresh-btn" @click="updateTransaction">
              <span class="mr-2">{{ $t("Refresh") }}</span>
              <b-icon
                icon="arrow-repeat"
                :animation="refreshAnimation"
              ></b-icon>
            </b-button>
          </div>
          <div class="table-header refresh-div">
            <a class="export-btn" :href="exportDeposit" target="_blank"
              >Export</a
            >
          </div>
        </div>

        <div
          class="d-flex justify-content-center align-items-center h-100 mt-4 spinning"
          v-if="isLoading"
        >
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </div>

        <b-table
          v-if="!isLoading"
          tbody-tr-class="transaction-rowClass"
          :items="deposit_balance"
          :per-page="0"
          :current-page="currentPage"
          :fields="depositFields"
          show-empty
          small
          responsive
          detailed
          empty-filtered-text="No Data"
          class="trx-table"
          id="deposit-table"
          @row-clicked="handleRowClicked"
        >
          <!-- start table header -->
          <template v-slot:head(hash)>
            <div class="table-header-content">
              <div>{{ $t("TX/ID") }}</div>
            </div>
          </template>

          <template v-slot:head(token)>
            <div class="table-header-content">
              <div>{{ $t("COIN") }}</div>
            </div>
          </template>

          <template v-slot:head(amount)>
            <div class="table-header-content">
              <div class="table-header-sub-content">
                {{ $t("AMOUNT") }}
                <img
                  :src="coinDepositSelected?.image"
                  class="coin-deposit-img"
                  width="20"
                  height="20"
                />
              </div>
            </div>
          </template>

          <template v-slot:head(service_fee)>
            <div class="table-header-content">
              <div class="table-header-sub-content">
                {{ $t("CHARGE FEE") }}
                <img
                  :src="coinDepositSelected?.image"
                  class="coin-deposit-img"
                  width="20"
                  height="20"
                  margin-left="10px"
                />
              </div>
            </div>
          </template>

          <template v-slot:head(real_gas)>
            <div class="table-header-content">
              <div class="table-header-sub-content">
                {{ $t("GATHER FEE") }}
                <img
                  :src="
                    chargeUsd == 'true'
                      ? require('../assets/icon/coins/usdt.svg')
                      : coinDepositSelected?.network.image
                  "
                  class="coin-deposit-img"
                  width="20"
                  height="20"
                  margin-left="10px"
                />
              </div>
            </div>
          </template>

          <template v-slot:head(timestamp)>
            <div class="table-header-content">
              <div>{{ $t("DATE/TIME") }}</div>
            </div>
          </template>

          <template v-slot:head(status)>
            <div class="table-header-content">
              <div>{{ $t("STATUS") }}</div>
            </div>
          </template>

          <template v-slot:head(action)>
            <div class="table-header-content">
              <div>{{ $t("ACTION") }}</div>
            </div>
          </template>
          <!-- end table header -->

          <!-- start table body -->
          <template #cell(hash)="row" id="trxID-td">
            <div
              class="trxID-td"
              v-if="row.item.hash == null || row.item.hash == ''"
            >
              —
            </div>
            <div class="trxID-td" v-else>
              {{ txIDFormatter(row.item.hash) }}
              <b-icon-box-arrow-up-right
                class="ml-2"
                type="button"
                @click.stop.prevent="
                  toExplorer(
                    coinDepositSelected?.network.symbol.toLowerCase(),
                    row.item.hash
                  )
                "
              ></b-icon-box-arrow-up-right>
            </div>
          </template>

          <template #cell(token)="row">
            {{ row.item.token.symbol }}
          </template>
          <template #cell(amount)="row">
            {{ formatNum(row.item.amount, deposit_volume_decimal) }}
          </template>
          <template #cell(service_fee)="row">
            {{
              row.item.service_fee == 0
                ? "nil"
                : formatNum(row.item.service_fee, deposit_volume_decimal + 2) ==
                  0
                ? "&lt;" + 1 / 10 ** (deposit_volume_decimal + 2)
                : formatNum(row.item.service_fee, deposit_volume_decimal + 2)
            }}
          </template>
          <template #cell(timestamp)="row">
            {{ timestampFormatter(row.item.created_at) }}
          </template>
          <template #cell(status)="row">
            <span
              class="table_status"
              :style="
                row.item.status == 'success'
                  ? 'color: var(--primary)'
                  : row.item.status == 'fail'
                  ? 'color: var(--danger)'
                  : 'color: var(--info)'
              "
            >
              {{ row.item.status }}
            </span>
          </template>
          <template #cell(action)="row">
            <span v-if="row.item.hash == null || row.item.hash == ''">N/A</span>
            <button
              v-else
              class="btn activate-btn"
              @click.stop.prevent="
                resendNotification(row.item.hash, 'deposit', row.item.to)
              "
            >
              {{ $t("Resend") }}
            </button>
          </template>
          <template #empty="scope">
            <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
          </template>
          <!-- end table body -->

          <!-- start expanded body -->
          <template slot="row-details" slot-scope="row">
            <div class="d-flex flex-column w-100 py-2 px-5">
              <div class="d-flex flex-row w-100">
                <div class="expand-left">{{ $t("TX ID") }}</div>

                <div class="expand-right">
                  <div class="mr-3 trxID-td">
                    {{
                      row.item.hash == null || row.item.hash == ""
                        ? "—"
                        : row.item.hash
                    }}
                  </div>

                  <div
                    class="tooltip__layout"
                    v-if="!(row.item.hash == null || row.item.hash == '')"
                  >
                    <b-icon-box-arrow-up-right
                      class="mx-2"
                      type="button"
                      @click.stop.prevent="
                        toExplorer(
                          coinDepositSelected?.network.symbol.toLowerCase(),
                          row.item.hash
                        )
                      "
                    ></b-icon-box-arrow-up-right>
                    <button
                      class="icon-btn pl-2"
                      @click="copyToClip(row.item.hash, `hash` + row.item.id)"
                    >
                      <inline-svg
                        :src="require('../assets/icon/copy.svg')"
                        width="18"
                        aria-label="copy"
                      ></inline-svg>
                      <span
                        class="tooltip__content"
                        :id="'hash' + row.item.id"
                        >{{ $t("Copied!") }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-row w-100">
                <div class="expand-left">{{ $t("Deposit ID") }}</div>
                <div class="expand-right">
                  <div class="mr-3">
                    {{
                      row.item.deposit_id == null || row.item.deposit_id == ""
                        ? "—"
                        : row.item.deposit_id
                    }}
                  </div>

                  <div
                    class="tooltip__layout"
                    v-if="
                      !(
                        row.item.deposit_id == null || row.item.deposit_id == ''
                      )
                    "
                  >
                    <button
                      class="icon-btn pl-2"
                      @click="
                        copyToClip(
                          row.item.deposit_id,
                          `deposit_id` + row.item.id
                        )
                      "
                    >
                      <inline-svg
                        :src="require('../assets/icon/copy.svg')"
                        width="18"
                        aria-label="copy"
                      ></inline-svg>
                      <span
                        class="tooltip__content"
                        :id="'deposit_id' + row.item.id"
                        >{{ $t("Copied!") }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-row w-100">
                <div class="expand-left">{{ $t("From Address") }}</div>

                <div class="expand-right">
                  <div class="mr-3">
                    {{
                      row.item.from == null || row.item.from == ""
                        ? "—"
                        : row.item.from
                    }}
                  </div>

                  <div
                    class="tooltip__layout"
                    v-if="!(row.item.from == null || row.item.from == '')"
                  >
                    <button
                      class="icon-btn pl-2"
                      @click="
                        copyToClip(row.item.from, `from_address` + row.item.id)
                      "
                    >
                      <inline-svg
                        :src="require('../assets/icon/copy.svg')"
                        width="18"
                        aria-label="copy"
                      ></inline-svg>
                      <span
                        class="tooltip__content"
                        :id="'from_address' + row.item.id"
                        >{{ $t("Copied!") }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-row w-100">
                <div class="expand-left">{{ $t("To Address") }}</div>

                <div class="expand-right">
                  <div class="mr-3">
                    {{
                      row.item.to == null || row.item.to == ""
                        ? "—"
                        : row.item.to
                    }}
                  </div>

                  <div
                    class="tooltip__layout"
                    v-if="!(row.item.to == null || row.item.to == '')"
                  >
                    <button
                      class="icon-btn pl-2"
                      @click="
                        copyToClip(row.item.to, `to_address` + row.item.id)
                      "
                    >
                      <inline-svg
                        :src="require('../assets/icon/copy.svg')"
                        width="18"
                        aria-label="copy"
                      ></inline-svg>
                      <span
                        class="tooltip__content"
                        :id="'to_address' + row.item.id"
                        >{{ $t("Copied!") }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- end expanded body -->
        </b-table>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="10"
          aria-controls="deposit-table"
          align="center"
        ></b-pagination>
      </div>

      <!-- Send -->
      <div class="mt-2" v-if="switch_tab === 2">
        <div class="rescan-container p-2">
          <div class="text-center">
            <div class="total-readonly">{{ $t("TOTAL AMOUNT") }}</div>
            <span>{{
              formatNum(totalWithdrawAmount ?? 0, withdraw_volume_decimal)
            }}</span>
          </div>

          <div class="text-center">
            <div class="total-readonly">{{ $t("TOTAL NETWORK FEE") }}</div>
            <span>{{ totalWithdrawNetworkFee ?? 0 }}</span>
          </div>

          <div class="text-center">
            <div class="total-readonly">{{ $t("TOTAL CHARGE FEE") }}</div>
            <span>{{
              formatNum(totalWithdrawChargeFee ?? 0, withdraw_volume_decimal)
            }}</span>
          </div>
        </div>

        <!-- Filter Section -->
        <div class="d-flex flex-wrap mt-3">
          <div class="table-header col-3 px-0">
            <div class="table-header-text">{{ $t("TX/ID") }}</div>
            <div class="search-box">
              <input
                type="search"
                class="txID-input form-control"
                :placeholder="$t(`Enter TX/ID`)"
                v-model="withdrawHash"
              />
              <img
                src="../assets/images/search.png"
                @click="fetchWithdrawalHistory"
              />
            </div>
          </div>

          <div class="table-header">
            <div class="table-header-text">{{ $t("coin") }}</div>
            <div>
              <!-- <select v-model="coinDepositSelected" @change="changeDepositSelectedSymbol" id="coinSortList"
                class="tx-dropdown">
                <option v-for="coinOption in coinOptions" :value="coinOption" :key="coinOption.id">
                  {{ coinOption.symbol }}
                </option>
              </select> -->
              <b-dropdown class="tx-filter tx-dropdown">
                <template #button-content>
                  <img
                    class="mr-2"
                    :src="coinWithdrawSelected?.image"
                    alt="coin-img-savvix"
                    width="25px"
                    height="25px"
                  />
                  <b class="mr-2"
                    >{{ coinWithdrawSelected?.symbol }}
                    <small>{{ coinWithdrawSelected?.network.symbol }}</small></b
                  >
                </template>
                <b-dropdown-item
                  v-for="coinOption in coinOptions"
                  :value="coinOption"
                  :key="coinOption.id"
                  @click="changeWithdrawSelectedSymbol(coinOption)"
                >
                  <img
                    class="mr-2"
                    :src="coinOption?.image"
                    alt="coin-savvix-img"
                    width="25px"
                    height="25px"
                  />
                  <b
                    >{{ coinOption.symbol }}
                    <small>{{ coinOption.network.symbol }}</small>
                  </b>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>

          <div class="table-header">
            <div class="table-header-text">{{ $t("status") }}</div>
            <b-dropdown class="tx-filter tx-dropdown">
              <template #button-content>
                <b class="mr-3">{{ selectedWithdrawStatus?.text }}</b>
              </template>
              <b-dropdown-item
                v-for="(statusObj, index) in withdrawStatus"
                :key="`withdraw${statusObj.value}`"
                @click="changeWithdrawSelectedStatus(index)"
              >
                <b>{{ statusObj.text }}</b>
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div class="table-header">
            <div class="table-header-text">{{ $t("Date/Time") }}</div>
            <div class="d-flex flex-column">
              <date-range-picker
                v-model="withdrawDateRange"
                @update="console.log(value)"
                :locale-data="locale"
              >
                <!--Optional scope for the input displaying the dates -->
                <template v-slot:input="picker">
                  {{ picker.startDate.toISOString().split("T")[0] }} -
                  {{ picker.endDate.toISOString().split("T")[0] }}
                </template>
              </date-range-picker>
            </div>
          </div>

          <div class="table-header refresh-div">
            <b-button class="refresh-btn" @click="updateTransaction">
              <span class="mr-2">{{ $t("Refresh") }}</span>
              <b-icon
                icon="arrow-repeat"
                :animation="refreshAnimation"
              ></b-icon>
            </b-button>
          </div>
          <div class="table-header refresh-div">
            <a class="export-btn" :href="exportWithdraw" target="_blank"
              >Export</a
            >
          </div>
        </div>

        <div
          class="d-flex justify-content-center align-items-center h-100 mt-4 spinning"
          v-if="isLoading"
        >
          <b-spinner variant="success" label="Spinning"></b-spinner>
        </div>

        <b-table
          v-if="!isLoading"
          tbody-tr-class="transaction-rowClass"
          :items="withdrawal_balance"
          :per-page="perWithdraw"
          :current-page="currentWithdraw"
          :fields="withdrawFields"
          show-empty
          small
          responsive
          empty-filtered-text="No Data"
          class="trx-table"
          id="withdraw-table"
          @row-clicked="handleRowClicked"
        >
          <!-- start table header -->
          <template v-slot:head(hash)>
            <div class="table-header-content">
              <div>{{ $t("TX/ID") }}</div>
            </div>
          </template>

          <template v-slot:head(token)>
            <div class="table-header-content">
              <div>{{ $t("COIN") }}</div>
            </div>
          </template>

          <template v-slot:head(amount)>
            <div class="table-header-content">
              <div class="table-header-sub-content">
                {{ $t("AMOUNT") }}
                <img
                  :src="coinWithdrawSelected?.image"
                  class="coin-deposit-img"
                  width="20"
                  height="20"
                />
              </div>
            </div>
          </template>

          <template v-slot:head(actual_gas)>
            <div class="table-header-content">
              <div class="table-header-sub-content">
                {{ $t("PRE CHARGE") }}
                <img
                  :src="
                    chargeUsd == 'true'
                      ? require('../assets/icon/coins/usdt.svg')
                      : coinWithdrawSelected?.network.image
                  "
                  class="coin-deposit-img"
                  width="20"
                  height="20"
                  margin-left="10px"
                />
              </div>
            </div>
          </template>

          <template v-slot:head(real_gas)>
            <div class="table-header-content">
              <div class="table-header-sub-content">
                {{ $t("ACTUAL SPEND") }}
                <img
                  :src="
                    chargeUsd == 'true'
                      ? require('../assets/icon/coins/usdt.svg')
                      : coinWithdrawSelected?.network.image
                  "
                  class="coin-deposit-img"
                  width="20"
                  height="20"
                  margin-left="10px"
                />
              </div>
            </div>
          </template>

          <template v-slot:head(service_fee)>
            <div class="table-header-content">
              <div>
                {{ $t("CHARGE FEE") }}
                <img
                  :src="coinWithdrawSelected?.image"
                  class="coin-deposit-img"
                  width="20"
                  height="20"
                  margin-left="10px"
                />
              </div>
            </div>
          </template>

          <template v-slot:head(created_at)>
            <div class="table-header-content">
              <div>{{ $t("DATE/TIME") }}</div>
            </div>
          </template>

          <template v-slot:head(status)>
            <div class="table-header-content">
              <div>{{ $t("STATUS") }}</div>
            </div>
          </template>

          <template v-slot:head(action)>
            <div class="table-header-content">
              <div>{{ $t("ACTION") }}</div>
            </div>
          </template>
          <!-- end table header -->

          <!-- start table body -->
          <template #cell(hash)="row">
            <div
              class="trxID-td"
              v-if="row.item.hash == null || row.item.hash == ''"
            >
              —
            </div>
            <div class="trxID-td" v-else>
              {{ txIDFormatter(row.item.hash) }}
              <b-icon-box-arrow-up-right
                class="ml-2"
                type="button"
                @click.stop.prevent="
                  toExplorer(
                    coinWithdrawSelected?.network.symbol.toLowerCase(),
                    row.item.hash
                  )
                "
              ></b-icon-box-arrow-up-right>
            </div>
          </template>
          <template #cell(token)="row">
            {{ row.item.token.symbol }}
          </template>
          <template #cell(amount)="row">
            {{ formatNum(row.item.amount, withdraw_volume_decimal) }}
          </template>
          <template #cell(actual_gas)="row">
            {{ formatNum(row.item.actual_gas, withdraw_volume_decimal) }}
          </template>

          <template #cell(real_gas)="row">
            {{
              row.item.is_refund
                ? formatNum(row.item.real_gas, withdraw_volume_decimal)
                : "Calculating..."
            }}
          </template>
          <template #cell(service_fee)="row">
            {{
              row.item.service_fee == 0
                ? "nil"
                : formatNum(
                    row.item.service_fee,
                    withdraw_volume_decimal + 2
                  ) == 0
                ? "&lt;" + 1 / 10 ** (withdraw_volume_decimal + 2)
                : formatNum(row.item.service_fee, withdraw_volume_decimal + 2)
            }}
          </template>
          <template #cell(created_at)="row">
            {{ dateFormatter(row.item.created_at) }}
          </template>
          <template #cell(status)="row">
            <span
              class="table_status"
              :style="
                row.item.status == 'success'
                  ? 'color: var(--primary)'
                  : row.item.status == 'fail'
                  ? 'color: var(--danger)'
                  : 'color: var(--info)'
              "
            >
              {{ row.item.status }}
            </span>
          </template>
          <template #cell(action)="row">
            <span v-if="row.item.hash == null || row.item.hash == ''">N/A</span>
            <button
              v-else
              class="btn activate-btn"
              @click.stop.prevent="
                resendNotification(row.item.hash, 'withdrawal', row.item.from)
              "
            >
              Resend
            </button>
          </template>

          <template #empty="scope">
            <p class="text-center">{{ (scope.emptyText = "No Data") }}</p>
          </template>
          <!-- end table body -->

          <!-- start expanded body -->
          <template slot="row-details" slot-scope="row">
            <div class="d-flex flex-column w-100 py-2 px-5">
              <div class="d-flex flex-row w-100">
                <div class="expand-left">{{ $t("TX ID") }}</div>

                <div class="expand-right">
                  <div class="mr-3 trxID-td">
                    {{
                      row.item.hash == null || row.item.hash == ""
                        ? "—"
                        : row.item.hash
                    }}
                  </div>

                  <div
                    class="tooltip__layout"
                    v-if="!(row.item.hash == null || row.item.hash == '')"
                  >
                    <button
                      class="icon-btn pl-2"
                      @click="copyToClip(row.item.hash, `hash` + row.item.id)"
                    >
                      <b-icon-box-arrow-up-right
                        class="mx-2"
                        type="button"
                        @click.stop.prevent="
                          toExplorer(
                            coinWithdrawSelected?.network.symbol.toLowerCase(),
                            row.item.hash
                          )
                        "
                      ></b-icon-box-arrow-up-right>
                      <inline-svg
                        :src="require('../assets/icon/copy.svg')"
                        width="18"
                        aria-label="copy"
                      ></inline-svg>
                      <span
                        class="tooltip__content"
                        :id="'hash' + row.item.id"
                        >{{ $t("Copied!") }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-row w-100">
                <div class="expand-left">{{ $t("Request ID") }}</div>

                <div class="expand-right">
                  <div class="mr-3">
                    {{
                      row.item.request_id == null || row.item.request_id == ""
                        ? "—"
                        : row.item.request_id
                    }}
                  </div>

                  <div
                    class="tooltip__layout"
                    v-if="
                      !(
                        row.item.request_id == null || row.item.request_id == ''
                      )
                    "
                  >
                    <button
                      class="icon-btn pl-2"
                      @click="
                        copyToClip(
                          row.item.request_id,
                          `request_id` + row.item.id
                        )
                      "
                    >
                      <inline-svg
                        :src="require('../assets/icon/copy.svg')"
                        width="18"
                        aria-label="copy"
                      ></inline-svg>
                      <span
                        class="tooltip__content"
                        :id="'request_id' + row.item.id"
                        >{{ $t("Copied!") }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-row w-100">
                <div class="expand-left">{{ $t("From Address") }}</div>

                <div class="expand-right">
                  <div class="mr-3">
                    {{
                      row.item.from == null || row.item.from == ""
                        ? "—"
                        : row.item.from
                    }}
                  </div>

                  <div
                    class="tooltip__layout"
                    v-if="!(row.item.from == null || row.item.from == '')"
                  >
                    <button
                      class="icon-btn pl-2"
                      @click="
                        copyToClip(row.item.from, `from_address` + row.item.id)
                      "
                    >
                      <inline-svg
                        :src="require('../assets/icon/copy.svg')"
                        width="18"
                        aria-label="copy"
                      ></inline-svg>
                      <span
                        class="tooltip__content"
                        :id="'from_address' + row.item.id"
                        >{{ $t("Copied!") }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-row w-100">
                <div class="expand-left">{{ $t("To Address") }}</div>

                <div class="d-flex flex-row expand-right">
                  <div class="mr-3">
                    {{
                      row.item.to == null || row.item.to == ""
                        ? "—"
                        : row.item.to
                    }}
                  </div>

                  <div
                    class="tooltip__layout"
                    v-if="!(row.item.to == null || row.item.to == '')"
                  >
                    <button
                      class="icon-btn pl-2"
                      @click="
                        copyToClip(row.item.to, `to_address` + row.item.id)
                      "
                    >
                      <inline-svg
                        :src="require('../assets/icon/copy.svg')"
                        width="18"
                        aria-label="copy"
                      ></inline-svg>
                      <span
                        class="tooltip__content"
                        :id="'to_address' + row.item.id"
                        >{{ $t("Copied!") }}</span
                      >
                    </button>
                  </div>
                </div>
              </div>

              <div class="d-flex flex-row w-100">
                <div class="expand-left">{{ $t("Remarks") }}</div>

                <div class="expand-right">
                  <div>
                    {{ row.item.remarks }}
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- end expanded body -->
        </b-table>

        <b-pagination
          v-model="currentWithdraw"
          :total-rows="totalWithdraw"
          :per-page="10"
          aria-controls="withdraw-table"
          align="center"
        ></b-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../helper/api.js";
import InlineSvg from "vue-inline-svg";
import moment from "moment";

import coinHelper from "../helper/coin.js";
import explorer from "../helper/explorer.js";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";

export default {
  name: "Transaction",
  components: {
    InlineSvg,
    DateRangePicker,
  },
  data() {
    return {
      deposit_data: [],
      switch_tab: 1,
      refreshAnimation: "",
      depositFields: [
        { key: "hash", label: "TX/ID" },
        { key: "token", label: "COIN" },
        { key: "amount", label: "AMOUNT" },
        // { key: "actual_gas", label: "NETWORK FEE" },
        { key: "service_fee", label: "CHARGE FEE" },
        { key: "real_gas", label: "GATHER FEE" },
        // { key: "actual_gas", label: "PRE CHARGE" },
        // { key: "real_gas", label: "ACTUAL SPEND" },
        { key: "timestamp", label: "DATE/TIME" },
        { key: "status", label: "STATUS" },
        { key: "action", label: "ACTION" },
      ],
      withdrawFields: [
        { key: "hash", label: "TX/ID" },
        { key: "token", label: "COIN" },
        { key: "amount", label: "AMOUNT" },
        { key: "service_fee", label: "CHARGE FEE" },
        { key: "actual_gas", label: "PRE CHARGE" },
        { key: "real_gas", label: "ACTUAL SPEND" },
        { key: "created_at", label: "DATE/TIME" },
        { key: "status", label: "STATUS" },
        { key: "action", label: "ACTION" },
      ],
      coin_sort: [
        { sortID: 0, sortName: "All" },
        { sortID: 1, sortName: "Alphabet A-Z" },
        { sortID: 2, sortName: "Alphabet Z-A" },
      ],
      coinSort_selected: "All",
      dateTime_sort: [
        { sortID: 0, sortName: "All" },
        { sortID: 1, sortName: "Past 7 Days" },
        { sortID: 2, sortName: "Past 30 Days" },
        { sortID: 3, sortName: "Past 90 Days" },
      ],
      dateTime_sort_selected: "All",
      status_sort: [
        { sortID: 0, sortName: "All" },
        { sortID: 1, sortName: "Pending" },
        { sortID: 2, sortName: "Failed" },
      ],
      status_sort_selected: "All",
      opened: [],
      isLoading: false,
      deposit_balance: [],
      withdrawal_balance: [],
      deposit_volume_decimal: 0,
      withdraw_volume_decimal: 0,
      currentPage: 1,
      currentWithdraw: 1,
      totalRows: 0,
      totalWithdraw: 0,
      perPage: 10,
      perWithdraw: 10,
      allOpenRows: [],
      coinOptions: [],
      coinDepositSelected: null,
      coinWithdrawSelected: null,
      // deposit sum
      totalDepositAmount: 0,
      totalDepositNetworkFee: 0,
      totalDepositChargeFee: 0,
      totalDepositGas: 0,
      // withdraw sum
      totalWithdrawAmount: 0,
      totalWithdrawNetworkFee: 0,
      totalWithdrawChargeFee: 0,
      depositStatus: [
        { value: "all", text: this.$t("All") },
        { value: "processing", text: this.$t("Processing") },
        { value: "fail_gather", text: this.$t("Fail") },
        { value: "success", text: this.$t("Success") },
      ],
      withdrawStatus: [
        { value: "all", text: this.$t("All") },
        { value: "pending_approve", text: this.$t("Pending Approve") },
        { value: "invalid", text: this.$t("Invalid") },
        { value: "broadcast", text: this.$t("Broadcast") },
        { value: "broadcasting", text: this.$t("Broadcasting") },
        { value: "success", text: this.$t("Success") },
        { value: "fail", text: this.$t("Fail") },
      ],
      selectedDepositStatus: null,
      selectedWithdrawStatus: null,
      networks: [
        { value: "trx", text: "TRX" },
        { value: "eth", text: "ETH" },
        { value: "bsc", text: "BSC" },
      ],
      selectedNetwork: "trx",
      blockNumber: null,
      depositStartDate: null,
      depositEndDate: null,
      withdrawStartDate: null,
      withdrawEndDate: null,
      refreshStatus: "Update",
      isSubmitting: false,

      // date range picker
      depositDateRange: {
        startDate: "2023-01-01",
        endDate: "2023-12-31",
      },

      withdrawDateRange: {
        startDate: "2023-01-01",
        endDate: "2023-12-31",
      },

      startDate: "2023-01-01",
      endDate: "2023-12-31",
      locale: {
        direction: "ltr", //direction of text
        format: "DD-MM-YYYY", //fomart of the dates displayed
        separator: " - ", //separator between the two ranges
        applyLabel: "Apply",
        cancelLabel: "Cancel",
        weekLabel: "W",
        customRangeLabel: "Custom Range",
        daysOfWeek: moment.weekdaysMin(), //array of days - see moment documenations for details
        monthNames: moment.monthsShort(), //array of month names - see moment documenations for details
        firstDay: 1, //ISO first day of week - see moment documenations for details
      },

      baseurl: process.env.VUE_APP_BASE_URL,
      exportDeposit: "",
      exportWithdraw: "",

      depositHash: "",
      withdrawHash: "",

      chargeUsd: localStorage.getItem("chargeUsd"),
    };
  },

  watch: {
    currentPage: {
      async handler() {
        this.isLoading = true;
        await this.fetchDepositHistory();
        console.log(this.currentPage);
      },
      deep: true,
    },

    // coinDepositSelected(){
    //   this.isLoading = true;
    //   this.fetchDepositHistory();
    // },

    // coinWithdrawSelected(){
    //   this.isLoading = true;
    //   this.fetchWithdrawalHistory();
    // },

    // perPage: {
    //   async handler() {
    //     this.isLoading = true
    //     await this.fetchDepositHistory();
    //   },
    //   deep: true,
    // },

    currentWithdraw: {
      async handler() {
        this.isLoading = true;
        await this.fetchWithdrawalHistory();
      },
      deep: true,
    },

    selectedDepositStatus() {
      this.isLoading = true;
      this.fetchDepositHistory();
    },

    selectedWithdrawStatus() {
      this.isLoading = true;
      this.fetchWithdrawalHistory();
    },

    depositStartDate(value) {
      if (this.depositEndDate && value != null) {
        this.isLoading = true;
        this.fetchDepositHistory();
      }
    },

    depositEndDate(value) {
      if (this.depositStartDate && value != null) {
        this.isLoading = true;
        this.fetchDepositHistory();
      }
    },

    withdrawStartDate(value) {
      if (this.withdrawEndDate && value != null) {
        this.isLoading = true;
        this.fetchWithdrawalHistory();
      }
    },

    withdrawEndDate(value) {
      if (this.withdrawStartDate && value != null) {
        this.isLoading = true;
        this.fetchWithdrawalHistory();
      }
    },

    "depositDateRange.startDate": function () {
      this.isLoading = true;
      this.fetchDepositHistory();
    },

    "withdrawDateRange.startDate": function () {
      this.isLoading = true;
      this.fetchWithdrawalHistory();
    },

    // 'depositDateRange.endDate':function(){
    //   this.isLoading = true;
    //   this.fetchDepositHistory();
    // }

    // perWithdraw: {
    //   async handler() {
    //     this.isLoading = true
    //     await this.fetchWithdrawalHistory();
    //   },
    //   deep: true,
    // }
  },

  async mounted() {
    await this.fetchCoins();
    this.depositDateRange = {
      startDate: moment().subtract(3, "months").format(),
      endDate: moment().format(),
    };

    this.withdrawDateRange = {
      startDate: moment().subtract(3, "months").format(),
      endDate: moment().format(),
    };

    // await this.fetchDepositHistory();
    // await this.fetchWithdrawalHistory();

    this.selectedDepositStatus = this.depositStatus[0];
    this.selectedWithdrawStatus = this.withdrawStatus[0];

    this.isLoading = true;
  },

  methods: {
    addCommas(nStr) {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },

    txIDFormatter(address) {
      return (
        address.substring(0, 4) + "..." + address.substring(address.length - 4)
      );
    },

    formatNum(num, decimal) {
      return coinHelper.formatNumberWithCommas(num, decimal);
    },

    timestampFormatter(value) {
      if (value) {
        // value = new Date(value/1000);
        return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
      }
    },

    dateFormatter(value) {
      if (value) {
        return moment(String(value)).format("DD/MM/YYYY HH:mm:ss");
      }
    },

    handleRowClicked(item) {
      this.allOpenRows.map((ele) => {
        if (ele.id !== item.id && ele._showDetails) {
          this.$set(ele, "_showDetails", !ele._showDetails);
        }
      });
      this.allOpenRows = [];
      this.$set(item, "_showDetails", !item._showDetails);
      this.allOpenRows.push(item);
    },

    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1);
      } else {
        this.opened.push(id);
      }
    },

    async fetchCoins() {
      try {
        const response = await api.apiCall("core", "GET", "/tokens/get-all");
        this.coinOptions = response.message;
        // this.coinDepositSelected = this.coinOptions.filter((item)=>item.api_symbol == 'TRX')[0];
        // this.coinWithdrawSelected = this.coinOptions.filter((item)=>item.api_symbol == 'TRX')[0];

        this.coinDepositSelected = this.coinOptions[0];
        this.coinWithdrawSelected = this.coinOptions[0];
      } catch (error) {
        console.error(error);
      }
    },

    async copyToClip(address, id) {
      event.stopImmediatePropagation();
      await this.$copyText(address).then(function () {
        var x = document.getElementById(id);
        x.classList.add("show");
        setTimeout(function () {
          x.classList.remove("show");
        }, 1000);
      });
    },

    async fetchDepositHistory() {
      this.generateLink();
      try {
        let params = {
          api_symbol: this.coinDepositSelected.api_symbol,
          status: this.selectedDepositStatus.value,
          // api_symbol:"TRX",
          order: "DESC",
          page: this.currentPage,
          take: 10,
          start:
            moment(this.depositDateRange.startDate).format("YYYY-MM-DD") +
            " 00:00:00",
          end:
            moment(this.depositDateRange.endDate).format("YYYY-MM-DD") +
            " 23:59:59",
          hash: this.depositHash,
        };
        const response = await api.apiCall(
          this.coinDepositSelected.network.symbol.toLowerCase(),
          "GET",
          "/deposits",
          params
        );
        // const response = await api.apiCall("trx",'GET','/deposits',params);
        this.deposit_balance = response.message.data;
        this.isLoading = false;
        this.totalRows = response.message.meta.itemCount;
        // this.currentPage = response.message.meta.page
        // this.perPage = (this.deposit_balance - 10) * this.currentPage;
        this.totalDepositGas = response.message.total[0].sum_gas;
        this.totalDepositAmount = response.message.total[0].sum;
        this.totalDepositNetworkFee = response.message.total[0].sum_network;
        this.totalDepositChargeFee = response.message.total[0].sum_charge;
        this.deposit_volume_decimal =
          response.message.total[0].token_volume_decimal * 1;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchWithdrawalHistory() {
      this.generateLink();
      try {
        let params = {
          api_symbol: this.coinWithdrawSelected.api_symbol,
          status: this.selectedWithdrawStatus.value,
          // api_symbol:"TRX",
          order: "DESC",
          page: this.currentWithdraw,
          take: 10,
          start:
            moment(this.withdrawDateRange.startDate).format("YYYY-MM-DD") +
            " 00:00:00",
          end:
            moment(this.withdrawDateRange.endDate).format("YYYY-MM-DD") +
            " 23:59:59",
          hash: this.withdrawHash,
        };

        const response = await api.apiCall(
          this.coinWithdrawSelected.network.symbol.toLowerCase(),
          "GET",
          "/withdrawals",
          params
        );
        // const response = await api.apiCall("trx",'GET','/withdrawals',params);
        this.withdrawal_balance = response.message.data;
        this.isLoading = false;
        this.totalWithdraw = response.message.meta.itemCount;
        // this.currentWithdraw = response.message.meta.page
        this.perWithdraw =
          (this.withdrawal_balance - 10) * this.currentWithdraw;
        this.totalWithdrawAmount = response.message.total[0].sum;
        this.totalWithdrawNetworkFee = response.message.total[0].sum_network;
        this.totalWithdrawChargeFee = response.message.total[0].sum_charge;
        this.withdraw_volume_decimal =
          response.message.total[0].token_volume_decimal * 1;

        // console.log(response.message.total[0])
      } catch (error) {
        this.isLoading = false;
        this.withdrawal_balance = null;
        console.error(error);
      }
    },

    async rescan() {
      this.isSubmitting = true;

      try {
        let params = {
          network: this.selectedNetwork,
          block_number: parseFloat(this.blockNumber),
        };
        await api.apiCall("core", "POST", "/rescan", params);
        this.$swal({
          text: `Rescan block ${this.blockNumber} success`,
          icon: "success",
        });

        this.isSubmitting = false;
        this.blockNumber = null;
        this.fetchDepositHistory();
        this.fetchWithdrawalHistory();
      } catch (error) {
        console.error(error);
        this.isSubmitting = false;
        this.$swal({
          text: error.message,
          icon: "error",
        });
      }
    },

    async resendNotification(hash, type, address) {
      try {
        let params = {
          hash: hash,
          type: type,
          address: address,
        };

        await api.apiCall(
          "core",
          "POST",
          "/notifications/resend/detail",
          params
        );
        this.$swal({
          text: this.$t("Resend notification success"),
          icon: "success",
        });

        // this.blockNumber = null;
        // this.fetchDepositHistory();
        // this.fetchWithdrawalHistory();
      } catch (error) {
        console.error(error);
        this.$swal({
          text: error.message,
          icon: "error",
        });
      }
    },

    async updateTransaction() {
      this.refreshStatus = "Updating...";
      this.refreshAnimation = "spin";

      await this.fetchDepositHistory();
      await this.fetchWithdrawalHistory();

      setTimeout(() => {
        this.refreshAnimation = "";
      }, 1000);

      this.refreshStatus = "Update";
    },

    async generateLink() {
      if (this.switch_tab == 1) {
        this.exportDeposit = `${
          this.baseurl
        }${this.coinDepositSelected.network.symbol.toLowerCase()}/deposits/download?page=${
          this.currentPage
        }&take=${
          this.perPage
        }&api_symbol=${this.coinDepositSelected.api_symbol.toLowerCase()}&start=${
          moment(this.depositDateRange.startDate).format("YYYY-MM-DD") +
          " 00:00:00"
        }&end=${
          moment(this.depositDateRange.endDate).format("YYYY-MM-DD") +
          " 23:59:59"
        }&status=${this.selectedDepositStatus.value}`;
      } else if (this.switch_tab == 2) {
        this.exportWithdraw = `${
          this.baseurl
        }${this.coinWithdrawSelected.network.symbol.toLowerCase()}/withdrawals/download?page=${
          this.currentPage
        }&take=${
          this.perPage
        }&api_symbol=${this.coinWithdrawSelected.api_symbol.toLowerCase()}&start=${
          moment(this.depositDateRange.startDate).format("YYYY-MM-DD") +
          " 00:00:00"
        }&end=${
          moment(this.depositDateRange.endDate).format("YYYY-MM-DD") +
          " 23:59:59"
        }&status=${this.selectedDepositStatus.value}`;
      }

      // this.refreshStatus = "Exporting...";
      // this.refreshAnimation = "spin";

      // setTimeout(()=>{
      //   this.refreshAnimation="";
      // },1000);
    },

    changeDepositSelectedSymbol(value) {
      this.coinDepositSelected = value;
      this.isLoading = true;
      this.fetchDepositHistory();
    },

    changeWithdrawSelectedSymbol(value) {
      this.coinWithdrawSelected = value;
      this.isLoading = true;
      this.fetchWithdrawalHistory();
    },

    changeDepositSelectedStatus(index) {
      this.selectedDepositStatus = this.depositStatus[index];
    },

    changeWithdrawSelectedStatus(index) {
      this.selectedWithdrawStatus = this.withdrawStatus[index];
    },

    toExplorer(network, hash) {
      let url = explorer.explorerLink(network, "tx", hash);
      window.open(url, "_blank").focus();
    },

    selectRescanNetwork(value) {
      this.selectedNetwork = value;
    },
  },
};
</script>

<style>
@import "../css/styles.css";

.trx-body {
  height: 100%;
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.trx-body::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.trx-body {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.trx-btn-container {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-left: 1rem;
  align-items: center;
}

.trx-inner-container {
  display: flex;
  flex-direction: row;
}

.trx-btn {
  background-color: transparent;
  min-width: 50px;
  width: 200px;
  border-radius: 30px;
  border: 2px solid var(--primary);
  color: var(--primary);
  stroke: var(--primary);
  font-weight: bold;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
}

.trx-btn:hover {
  /* color: var(--primary);
  border: 2px solid var(--primary);
  background-color: var(--primary-10);
  box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px; */

  background-color: var(--primary);
  color: var(--light);
  stroke: var(--light);
  transition: 0.5s;
}

.trx-btn_active {
  background-color: var(--primary);
  color: var(--light);
  stroke: var(--light);
}

.btn-export {
  background-color: transparent;
  color: black;
  border: none;
  font-size: 13px;
  display: flex;
  place-items: center;
  place-self: end;
  text-transform: uppercase;
  cursor: pointer;
}

/* table */
.table-responsive {
  display: inline-table;
}

.trx-table table {
  border-collapse: separate;
  border-spacing: 0 8px;
}

.trx-table tr {
  background-color: var(--light);
}

.trx-table thead tr {
  background-color: transparent;
}

.trx-table td {
  text-align: center;
}

.trx-table td:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.trx-table td:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.trx-table.b-table-has-details {
  background-color: aliceblue;
}

.transaction-rowClass:hover {
  cursor: pointer;
  background-color: aliceblue;
}

.trx-table.b-table-details:hover {
  cursor: auto;
  background-color: white;
}

.expand-left {
  width: 15%;
  padding: 7px 0px;
  text-align: left;
}

.expand-right {
  width: 85%;
  text-align: left;
  padding-left: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* select {
  text-align-last: center;
} */

#trx-table-row {
  background-color: var(--light);
  border-bottom: none;
  border-radius: 20px;
  padding: 7px 20px;
}

.table-header {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto 10px;
}

.table-header-text {
  text-transform: capitalize;
  text-align: initial;
  /* margin-left: 15px; */
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
}

.table-header-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.table-header-sub-content {
  display: flex;
  align-items: center;
}

.coin-deposit-img {
  margin-left: 5px;
}

.table_status {
  text-transform: capitalize;
}

.opened-tr td {
  text-align: left;
}

.opened-tr td:nth-child(2) {
  padding-left: 3rem;
}

::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

:-ms-input-placeholder {
  text-align: center;
}

.trxID-td {
  color: var(--primary);
  font-style: italic;
  /* width: 300px; */
}

.txID-input {
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  border-radius: 15px;
  /* min-width: 100px; */
  /* width: 150px; */
  color: black;
  /* text-indent: 5px; */
  font-size: 18px;
  height: 35px;
}

.txID-input::placeholder {
  text-align: left;
}

.txID-readonly {
  background-color: transparent;
  /* background-color: rgba(0, 0, 0, 0.1); */
  border: none;
  border-radius: 15px;
  /* min-width: 100px;
  width: 120px; */
  color: var(--light) !important;
}

.tx-dropdown {
  border: none;
  color: black;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 30px;
  height: 35px;
  width: 150px;
  min-width: 150px;
}

.refresh-div {
  align-items: baseline;
  margin: 0;
  display: flex;
  justify-content: flex-end;
}

.refresh-btn {
  height: 35px;
}

.export-btn {
  height: 35px;
  margin: 0px 20px;
  align-items: center;
  font-weight: bold;
  display: flex;
  color: var(--info);
}

.export-btn:hover {
  color: var(--info);
}

.spinning {
  height: 600px !important;
}

.pagination-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.pagination-div button {
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 32px;
  width: 32px;
  margin: 0.3rem 5px;
  border: none;
  background-color: white;
}

.pagination-button {
  background-color: var(--primary) !important;
  color: white !important;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0 2px 2px 0 rgb(0 0 0 / 14%),
    0 1px 5px 0 rgb(0 0 0 / 12%) !important;
}

.rescan-container {
  background-color: var(--info);
  color: var(--light);
  display: flex;
  justify-content: space-evenly;
  font-size: 18px;
}

.rescan-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}

.rescan-wrapper input {
  border-radius: 50px;
  border: none;
  text-align: left;
  background-color: var(--light-gray);
  color: var(--dark);
}

.rescan-wrapper .rescan-dropdown button {
  width: 100%;
  border-radius: 50px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--light-gray);
  color: var(--dark);
}

.rescan-dropdown-toggle {
  background-color: var(--light-gray) !important;
  color: var(--dark) !important;
}

.rescan-dropdown button:hover,
.rescan-menu .rescan-dropdown button:focus {
  width: 100%;
  background-color: var(--light-gray);
}

.rescan-menu {
  width: 100%;
}

.tx-filter .btn {
  color: var(--dark);
  background-color: var(--light-gray);
  padding: 0;
}

.tx-filter .btn:hover,
.tx-filter .btn:active {
  background-color: var(--light-gray);
  color: var(--dark);
}

/* .coin-filter .btn:active{
  background-color: red !important;
} */

.trx-body .reportrange-text {
  min-width: 250px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .trx-btn-container {
    flex-direction: column;
    align-items: end;
  }

  .trx-inner-container {
    width: 100%;
  }

  .btn-export {
    margin-top: 10px;
  }

  .table-responsive {
    display: block;
  }

}

.search-box {
  position: relative;
  display: flex;
  flex-direction: row;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 30px;
}

.search-box > input {
  width: 90%;
  background-color: transparent !important;
}

.search-box > img {
  width: 25px;
  height: 70%;
  position: absolute;
  right: 10px;
  display: flex;
  align-self: center;
  cursor: pointer;
}

.search-box > input:focus {
  outline: none;
  box-shadow: none;
}
</style>

<style scoped>
@media only screen and (max-width: 768px) {
    /* header mobile css */
  .header-title {
    height: auto;
  }
  .trx-btn-container {
    margin-left: 0;
  }

  .header-title b {
    margin: 10px 0;
  }
  .trx-btn {
    width: 110px;
  }
  .refresh-div {
    margin: 10px !important;
  }
  .text-danger{
    font-size: 14px;
  }
}
</style>
